import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import logo from '../../assets/logoForHeader.svg';
import logoPhone from '../../assets/logo-width-to600.svg';
import { Breadcrumb, Button, Form, message } from 'antd';
import arrowRight from '../../assets/arrow-right.svg';
import activeArrow from '../../assets/arrow-right-active.svg';
import general from '../../assets/generalIcon.svg';
import password from '../../assets/PasswordIcon.svg';
import './index.scss';
import DefaultAvatar from '../../assets/DefaultAvatar.svg';
import RoundedWhite from '../../assets/RoundedWhite.svg';
import { useLazyGetInvatationDataQuery, useSignUpMutation } from '../../api/users';
import { FloatInput } from '../../components/Fields/FloatInput';
import { yupSync } from '../../utils';
import { PhoneNumberInput } from '../../components/Fields/PhoneNumberInput';
import { FloatSelect } from '../../components/Fields/FloatSelect';
import * as Yup from 'yup';
import { useAppDispatch } from '../../hooks';
import { setAuthUser } from '../../reducers/auth/authSlice';
import { usePreventSpaceTyping } from '../../hooks/usePreventSpaceTyping';
import { Loader } from '../../components/Loader/Loader';
import { CustomErrorType } from '../../api/types/global';

const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('This is a required field'),
    last_name: Yup.string().required('This is a required field'),
    email: Yup.string().email('Enter your email: e.g. email@domain.com').required('This is a required field'),
    school: Yup.object().required('This is a required field'),
    job: Yup.string().required('This is a required field'),
    phone_number: Yup.string()
        .required('This is a required field')
        .matches(/^[^_]*$/, 'This is a required field'),
    password: Yup.string()
        .required('This is a required field')
        .test('password-requirements', '', (value) => {
            const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
            return passwordRegex.test(value);
        }),
    confirmPassword: Yup.string().required('This is a required field')
});

export const SignUp = () => {
    const [getData, { data, isError }] = useLazyGetInvatationDataQuery();

    const [
        signUp,
        { data: signUpUserData, isSuccess: signUpUserSuccess, isLoading: signUpUserLoading, error: errorsignUpUser }
    ] = useSignUpMutation();

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [step, setStep] = useState('general');
    const [imageUrl, setImageUrl] = useState<string>('');
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [form] = Form.useForm<FormData>();
    const [fileAvatar, setFileAvatar] = useState('');
    const [changed, setChanged] = useState(false);
    const [err, setErr] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [formDataFormFirstStep, setFormDataFormFirstStep] = useState<any>();
    const dispatch = useAppDispatch();
    const [passwordErr, setPasswordErr] = useState(false);
    const [fileErr, setFileErr] = useState('');
    const { handleKeyPress } = usePreventSpaceTyping();

    const [screenWidth, setScreenWidth] = useState<number>(window.screen.width);
    function handleResize() {
        setScreenWidth(window.screen.width);
    }

    window.addEventListener('resize', handleResize);

    useEffect(() => {
        if (isError) {
            navigate('/expired');
        }
    }, [isError]);

    useEffect(() => {
        if (!data) return;

        if (data?.data?.role?.title === 'School User') {
            form.setFieldsValue({
                //@ts-ignore
                first_name: data.data.first_name ? data.data.first_name : '',
                last_name: data.data.last_name ? data.data.last_name : '',
                email: data.data.email,
                phone_number: data.data.phone_number ? data.data.phone_number : '',
                school: data.data.school,
                job: data.data.job_title ? data.data.job_title : ''
            });
        }
        if (data?.data?.role?.title === 'Tender Representative' || data?.data?.role?.title === 'Super Admin') {
            form.setFieldsValue({
                //@ts-ignore
                first_name: data.data.first_name ? data.data.first_name : '',
                last_name: data.data.last_name ? data.data.last_name : '',
                email: data.data.email
            });
        }
        setImageUrl(data.data.avatar);
    }, [data]);

    const getInvitationData = async () => {
        try {
            await getData(searchParams.get('token'));
        } catch {
            navigate('/expired');
        }
    };

    useEffect(() => {
        if (!searchParams.get('token')) {
            // navigate('/expired');
        } else {
            getInvitationData();
        }
    }, [searchParams]);

    const handleFileChange = (event: any) => {
        const allowedTypes = ['image/jpeg', 'image/png', 'image/heic', 'image/jpg'];
        const maxFileSize = 5 * 1024 * 1024;
        const file = event.target.files[0];

        if (file && allowedTypes?.includes(file.type) && file.size < maxFileSize) {
            setChanged(true);
            setImageUrl(URL.createObjectURL(event.target.files[0]));
            setFileAvatar(event.target.files[0]);
            setFileErr('');
        } else {
            if (!allowedTypes?.includes(file.type)) {
                setFileErr('You can upload files in the following formats: .jpg, .jpeg, .png, .heic');
            }
            if (file.size > maxFileSize) {
                setFileErr('The file size limit is 5 MB');
            }
            event.target.value = '';
        }
    };

    const clickUpload = () => {
        fileInputRef?.current?.click();
    };
    const validationHandler = async (params: any) => {
        const signUpData = {
            token: searchParams.get('token'),
            data: params
        };
        try {
            await signUp(signUpData);

            setStep('password');
        } catch {
            messageApi.error('Something went wrong!');
            // navigate('/expired');
        }
    };

    useEffect(() => {
        if (signUpUserSuccess && step === 'password' && signUpUserData) {
            dispatch(
                setAuthUser({
                    authToken: signUpUserData.data?.access_token,
                    id: null,
                    name: null,
                    email: null
                })
            );
            navigate('/dashboard');
        }
    }, [signUpUserSuccess, signUpUserData]);

    const signUpHandler = async (params: any) => {
        const signUpData = {
            token: searchParams.get('token'),
            data: params
        };
        try {
            await signUp(signUpData).unwrap();
            await messageApi.success('You have successfully registered');
        } catch {}
    };

    useEffect(() => {
        if (errorsignUpUser) {
            if ((errorsignUpUser as CustomErrorType)?.data?.message === 'The Password format is invalid') {
                messageApi.error('The Password format is invalid');
            }
        }
    }, [errorsignUpUser]);

    const handleSubmit = () => {
        form.validateFields()
            .then((values: any) => {
                if (step === 'general') {
                    const formData = new FormData();
                    formData.append('step', step);
                    formData.append('first_name', values.first_name.trim());
                    formData.append('last_name', values.last_name.trim());
                    formData.append('role', data.data.role.name);

                    if (data?.data?.role?.title === 'School User') {
                        formData.append('phone_number', values.phone_number);
                        formData.append('job_title', values.job.trim());
                    }

                    if (changed && fileAvatar) {
                        formData.append('avatar', fileAvatar);
                    }
                    if (changed && !fileAvatar) {
                        formData.append('reset_avatar', '1');
                    }

                    const object: any = {
                        first_name: values.first_name.trim(),
                        last_name: values.last_name.trim(),
                        role: data.data.role.name
                    };

                    if (data?.data?.role?.title === 'School User') {
                        object.phone_number = values.phone_number;
                        object.job_title = values.job.trim();
                    }

                    if (changed && fileAvatar) {
                        object.avatar = fileAvatar;
                    }
                    if (changed && !fileAvatar) {
                        object.reset_avatar = 1;
                    }
                    setFormDataFormFirstStep(object);
                    validationHandler(formData);
                }
                if (step === 'password') {
                    const formData = new FormData();
                    formData.append('password', values.password);
                    formData.append('password_confirmation', values.confirmPassword);
                    formData.append('step', step);
                    formData.append('first_name', formDataFormFirstStep?.first_name);
                    formData.append('last_name', formDataFormFirstStep?.last_name);
                    formData.append('role', data.data.role.name);
                    if (data?.data?.role?.title === 'School User') {
                        formData.append('phone_number', formDataFormFirstStep?.phone_number);
                        formData.append('job_title', formDataFormFirstStep?.job_title);
                    }

                    if (formDataFormFirstStep.avatar) {
                        formData.append('avatar', formDataFormFirstStep.avatar);
                    }
                    if (!formDataFormFirstStep.avatar) {
                        formData.append('reset_avatar', '1');
                    }
                    signUpHandler(formData);
                }
            })
            .catch((errors) => {
                errors.errorFields.map((elem: any) => {
                    if (elem.name[0] === 'phone_number') {
                        if (elem.errors[0]) {
                            setErr(true);
                        } else {
                            setErr(false);
                        }
                    }
                    if (elem.name[0] === 'password') {
                        if (elem.errors[0] === '') {
                            setPasswordErr(true);
                        } else {
                            setPasswordErr(false);
                        }
                    }
                });
                const element = errors.errorFields.find((elem: any) => elem.name[0] === 'password');
                if (!element) {
                    setPasswordErr(false);
                }
            });
    };

    return (
        <>
            {contextHolder}
            <div className="signup">
                <div className="signup__header header">
                    {screenWidth > 600 && <img src={logo} className="header__logo" />}
                    {screenWidth <= 600 && <img src={logoPhone} className="header__logo" />}
                    <div className="header__breadcrumb">
                        <Breadcrumb
                            items={[
                                {
                                    title: (
                                        <div
                                            className={`header__item active`}
                                            onClick={() => setStep('general')}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <span>Personal Info</span>
                                        </div>
                                    )
                                },
                                {
                                    title: (
                                        <div className={`header__item ${step === 'password' ? 'active' : ''}`}>
                                            <img
                                                src={step === 'password' ? activeArrow : arrowRight}
                                                style={{ margin: '0 10px 0 0' }}
                                            />
                                            <span>Password</span>
                                        </div>
                                    )
                                },
                                {
                                    title: (
                                        <div className="header__item">
                                            <img src={arrowRight} />
                                            <span style={{ visibility: 'hidden' }}>ssegeg</span>
                                        </div>
                                    )
                                }
                            ]}
                        />
                    </div>
                </div>
                <div className="signup__main main">
                    {step === 'general' && (
                        <div className="main__block">
                            <div className="main__form form">
                                <div className="form__title">
                                    Welcome to the Hive! <br />
                                    Your tender collaboration space!
                                </div>
                                <div className="form__subtitle">Please fill in personal data</div>

                                <div className="form__addbtn">
                                    {imageUrl && (
                                        <img
                                            src={RoundedWhite}
                                            className="form__delete-avatar-btn"
                                            onClick={() => {
                                                setChanged(true);
                                                setImageUrl('');
                                            }}
                                        />
                                    )}
                                    <input
                                        type="file"
                                        accept=".jpg, .jpeg, .png, .heic"
                                        onChange={handleFileChange}
                                        style={{ display: 'none' }}
                                        ref={fileInputRef}
                                    />

                                    {imageUrl ? (
                                        <img src={imageUrl} alt="avatar" className="upload-image" />
                                    ) : (
                                        <img src={DefaultAvatar} />
                                    )}

                                    <div className="form__addtext" onClick={clickUpload}>
                                        {imageUrl ? 'Edit Photo' : 'Add Photo'}
                                    </div>
                                </div>
                                {fileErr && <div className="fields__file-error">{fileErr}</div>}

                                {data?.data?.role?.title === 'School User' && (
                                    <div className="signup__fields fields">
                                        <div className="fields__subtitle">All fields are mandatory</div>
                                        <Form form={form} onFinish={handleSubmit} validateTrigger="onBlur">
                                            <div className="fields__block">
                                                <div className="fields__row">
                                                    <Form.Item
                                                        className={`${
                                                            screenWidth > 600 ? 'small-input-wrapper' : 'input-wrapper'
                                                        }`}
                                                        name="first_name"
                                                        rules={yupSync('first_name', validationSchema, true)}
                                                    >
                                                        <FloatInput
                                                            floatLabel="First Name"
                                                            className="input"
                                                            maxLength={60}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        className={`${
                                                            screenWidth > 600 ? 'small-input-wrapper' : 'input-wrapper'
                                                        }`}
                                                        name="last_name"
                                                        rules={yupSync('last_name', validationSchema, true)}
                                                    >
                                                        <FloatInput
                                                            floatLabel="Last Name"
                                                            className="input"
                                                            maxLength={60}
                                                        />
                                                    </Form.Item>
                                                </div>

                                                <Form.Item
                                                    className="input-wrapper"
                                                    name="email"
                                                    rules={yupSync('email', validationSchema, true)}
                                                >
                                                    <FloatInput
                                                        floatLabel="Email"
                                                        className="input"
                                                        maxLength={60}
                                                        required={true}
                                                        disabled={true}
                                                        onKeyPress={handleKeyPress}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    className="input-wrapper"
                                                    name="phone_number"
                                                    rules={yupSync('phone_number', validationSchema, true)}
                                                >
                                                    <PhoneNumberInput
                                                        floatLabel="Phone Number"
                                                        className="input"
                                                        maxLength={256}
                                                        value={data?.data?.phone_number}
                                                        placeholder={undefined}
                                                        required={undefined}
                                                        onChange={() => {}}
                                                        hasError={() => !!form.getFieldError('phone_number').length}
                                                        onBlur={() => yupSync('phone_number', validationSchema, true)}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div className="fields__block">
                                                <Form.Item
                                                    className="input-wrapper"
                                                    name="school"
                                                    rules={yupSync('school', validationSchema, true)}
                                                >
                                                    <FloatSelect
                                                        search={''}
                                                        floatLabel="School"
                                                        className="input-select"
                                                        required={true}
                                                        err={false}
                                                        setSearch={() => {}}
                                                        options={[]}
                                                        onChange={''}
                                                        value={data?.data?.school}
                                                        form={form}
                                                        disabled={true}
                                                        hasError={() => false}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div className="fields__block">
                                                <Form.Item
                                                    className="input-wrapper"
                                                    name="job"
                                                    rules={yupSync('job', validationSchema, true)}
                                                >
                                                    <FloatInput
                                                        floatLabel="Job Title"
                                                        className="input"
                                                        maxLength={60}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <Button
                                                className="fields__btn"
                                                type="primary"
                                                onClick={handleSubmit}
                                                loading={signUpUserLoading}
                                            >
                                                {signUpUserLoading ? <Loader /> : 'Continue'}
                                            </Button>
                                        </Form>
                                    </div>
                                )}

                                {(data?.data?.role?.title === 'Tender Representative' ||
                                    data?.data?.role?.title === 'Super Admin') && (
                                    <div className="signup__fields fields">
                                        <div
                                            className="fields__role"
                                            style={
                                                data?.data?.role?.title === 'Super Admin'
                                                    ? { background: '#001965', color: 'white', width: '100px' }
                                                    : {}
                                            }
                                        >
                                            {data?.data?.role?.title}
                                        </div>
                                        <div className="fields__subtitle">All fields are mandatory</div>
                                        <Form form={form} onFinish={handleSubmit}>
                                            <div className="fields__block">
                                                <div className="fields__row">
                                                    <Form.Item
                                                        className={`${
                                                            screenWidth > 600 ? 'small-input-wrapper' : 'input-wrapper'
                                                        }`}
                                                        name="first_name"
                                                        rules={yupSync('first_name', validationSchema, true)}
                                                    >
                                                        <FloatInput
                                                            floatLabel="First Name"
                                                            className="input"
                                                            maxLength={60}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        className={`${
                                                            screenWidth > 600 ? 'small-input-wrapper' : 'input-wrapper'
                                                        }`}
                                                        name="last_name"
                                                        rules={yupSync('last_name', validationSchema, true)}
                                                    >
                                                        <FloatInput
                                                            floatLabel="Last Name"
                                                            className="input"
                                                            maxLength={60}
                                                        />
                                                    </Form.Item>
                                                </div>

                                                <Form.Item
                                                    className="input-wrapper"
                                                    name="email"
                                                    rules={yupSync('email', validationSchema, true)}
                                                >
                                                    <FloatInput
                                                        floatLabel="Email"
                                                        className="input"
                                                        maxLength={60}
                                                        required={true}
                                                        disabled={true}
                                                        onKeyPress={handleKeyPress}
                                                    />
                                                </Form.Item>
                                            </div>

                                            <Button className="fields__btn" type="primary" onClick={handleSubmit}>
                                                {signUpUserLoading ? <Loader /> : 'Continue'}
                                            </Button>
                                        </Form>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {step === 'password' && (
                        <div className="main__block">
                            <div className="main__form form password">
                                <div className="form__title">Set up your password</div>

                                <div className="signup__fields fields">
                                    <Form form={form} onFinish={handleSubmit} validateTrigger="onBlur">
                                        <div className="fields__block">
                                            <Form.Item
                                                className="input-wrapper"
                                                name="password"
                                                rules={yupSync('password', validationSchema, true)}
                                            >
                                                <FloatInput
                                                    type="password"
                                                    floatLabel="Password (>8 characters)"
                                                    className="input"
                                                    maxLength={32}
                                                    onBlur={() => {
                                                        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
                                                        const res = passwordRegex.test(form.getFieldValue('password'));
                                                        if (!res && form.getFieldValue('password')) {
                                                            setPasswordErr(true);
                                                        } else {
                                                            setPasswordErr(false);
                                                        }
                                                    }}
                                                    onKeyPress={handleKeyPress}
                                                />
                                            </Form.Item>
                                            <div
                                                className="fields__label"
                                                style={
                                                    passwordErr ? { color: '#e80a0f', margin: '-24px 0 16px 0' } : {}
                                                }
                                            >
                                                {`The password should contain numbers and at least one upper case and lower case letter (>8 characters)`}
                                            </div>
                                            <Form.Item
                                                className="input-wrapper"
                                                name="confirmPassword"
                                                rules={[
                                                    ...yupSync('confirmPassword', validationSchema),
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            if (!value || getFieldValue('password') === value) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject(
                                                                new Error('Oops! Your passwords do not match')
                                                            );
                                                        }
                                                    })
                                                ]}
                                                dependencies={['password']}
                                            >
                                                <FloatInput
                                                    type="password"
                                                    floatLabel="Repeat Password (>8 characters)"
                                                    className="input"
                                                    maxLength={32}
                                                    onKeyPress={handleKeyPress}
                                                />
                                            </Form.Item>
                                        </div>

                                        <Button className="fields__btn" type="primary" onClick={handleSubmit}>
                                            {signUpUserLoading ? <Loader /> : 'Create Account'}
                                        </Button>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className={`main__block ${screenWidth < 1280 ? 'image' : ''}`}>
                        <div className={step === 'general' ? 'general' : 'password'}>
                            {step === 'general' && <img src={general} className="image__item" />}
                            {step === 'password' && <img src={password} className="image__item" />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
