import { FC, useState, useEffect } from 'react';
import { Button, Form, Modal } from 'antd';
import { Select } from '../Fields/Select';
import { yupSync } from '../../utils';
import { DatePickerInput } from '../Fields/DatePickerInput';
import { FloatTextArea } from '../Fields/FloatTextArea';
import { useUpdateTaskMutation } from '../../api/tenders';
import { Loader } from '../Loader/Loader';
import { usePreventSpaceTyping } from '../../hooks/usePreventSpaceTyping';
import { tenderDataProps } from '../../pages/TenderViewPage/types';
import { CustomErrorType } from '../../api/types/global';

import close from '../../assets/RoundedClose.svg';
import clock from '../../assets/clock.svg';

import moment, { Moment } from 'moment';
import * as Yup from 'yup';

interface MessageApi {
    success: (message: string) => void;
    error: (message: string) => void;
}

interface ModalProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    messageApi?: MessageApi;
    data?: tenderDataProps;
    taskId?: number | null | string;
    isCreateModal: boolean;
}

interface TimeObject {
    value: string | null;
    id: number | null;
}

interface FormData {
    date: string | null;
    time_from: TimeObject;
    time_to: TimeObject;
    link: string | null;
    comment: string | null;
}
interface FormDataToUpdate {
    date: string | null;
    time_from: string;
    time_to: string;
    link: string | null;
    comment: string | null;
}

const validationSchema = Yup.object().shape({
    date: Yup.string().required('This is a required field'),
    time_from: Yup.object().required('This is a required field'),
    time_to: Yup.object().required('This is a required field'),
    link: Yup.string()
        .matches(/^(https?:\/\/)/, 'Oops! Your link is not correct')
        .required('This is a required field'),
    comment: Yup.string().nullable()
});

export const CreateMeetingModal: FC<ModalProps> = ({
    openModal,
    setOpenModal,
    messageApi,
    data,
    setTrigger,
    taskId,
    isCreateModal
}) => {
    const [screenWidth, setScreenWidth] = useState<number>(window.screen.width);
    function handleResize() {
        setScreenWidth(window.screen.width);
    }
    window.addEventListener('resize', handleResize);
    const { handleKeyPress } = usePreventSpaceTyping();
    const [form] = Form.useForm<FormData>();

    const [isConfirmModal, setIsConfirmModal] = useState(false);
    const [changed, setChanged] = useState(false);
    const [startTime, setStartTime] = useState<TimeObject | null>(null);
    const [date, setDate] = useState<Moment>(moment().add(1, 'days'));
    const [dependentArray, setDependentArray] = useState<TimeObject[]>([]);
    const [changedData, setChangedData] = useState(false);
    const [isFirstRender, setIsFirstRender] = useState(true);

    const [updateTask, { isSuccess, isError, error, isLoading }] = useUpdateTaskMutation();

    function generateTimeArray(): TimeObject[] {
        const timeArray: TimeObject[] = [
            { value: '07:00', id: 1 },
            { value: '07:30', id: 3 },
            { value: '08:00', id: 5 },
            { value: '08:30', id: 7 },
            { value: '09:00', id: 9 },
            { value: '09:30', id: 11 },
            { value: '10:00', id: 13 },
            { value: '10:30', id: 15 },
            { value: '11:00', id: 17 },
            { value: '11:30', id: 19 },
            { value: '12:00', id: 21 },
            { value: '12:30', id: 23 },
            { value: '13:00', id: 25 },
            { value: '13:30', id: 27 },
            { value: '14:00', id: 29 },
            { value: '14:30', id: 31 },
            { value: '15:00', id: 33 },
            { value: '15:30', id: 35 },
            { value: '16:30', id: 38 }
        ];
        return timeArray;
    }

    const timeArray: TimeObject[] = generateTimeArray();

    function generateDependentArray(baseTime: string | null) {
        const index = timeArray.findIndex((elem: TimeObject) => {
            return elem.value === baseTime;
        });
        const newArray = timeArray.slice(index + 1);
        newArray.push({ value: '17:00', id: 40 });
        return newArray;
    }

    useEffect(() => {
        if (startTime) {
            const dependentArray = generateDependentArray(startTime?.value);
            setDependentArray(dependentArray);
        }
    }, [startTime]);

    useEffect(() => {
        if (!isCreateModal && data) {
            const result = data.phase_tasks[0];

            setStartTime({ value: result.time_from, id: null });
            form.setFieldsValue({
                date: result.date,
                time_from: { value: result.time_from || '', id: null },
                time_to: { value: result.time_to || '', id: null },
                link: result.link,
                comment: result.comments.tender_representative
            });
            setIsFirstRender(false);
        }
    }, [isCreateModal, data]);

    useEffect(() => {
        if (isFirstRender) return;
        form.resetFields(['time_to']);
    }, [changedData]);

    useEffect(() => {
        if (isSuccess) {
            messageApi?.success('Great news! The changes to the tender have been saved successfully');
        }
        if (isError) {
            if (error) {
                messageApi?.error((error as CustomErrorType)?.data?.message);
            } else {
                messageApi?.error('Something went wrong, please try again');
            }
        }
    }, [isSuccess, isError]);

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: FormData) => {
                const formData: any = {
                    date: moment(values.date).format('YYYY-MM-DD'),
                    time_from: values.time_from.value && values.time_from.value.split(' ')[0],
                    time_to: values.time_to.value && values.time_to.value.split(' ')[0],
                    link: values.link,
                    comment: values.comment ? values.comment : null
                };
                updateTaskFunc(formData);
            })
            .catch(() => {});
    };

    const updateTaskFunc = async (prop: FormDataToUpdate) => {
        const formData = {
            ids: {
                tender_id: data?.id,
                task_id: taskId
            },
            data: prop
        };
        try {
            await updateTask(formData).unwrap();
            await setOpenModal(false);
            setTrigger((prev) => !prev);
        } catch {}
    };

    return (
        <>
            <Modal
                className="modal-modal"
                destroyOnClose
                centered
                open={openModal}
                onOk={() => handleFormSubmit()}
                onCancel={() => {
                    if (form.isFieldsTouched() || changed) {
                        setIsConfirmModal(true);
                    } else {
                        setOpenModal(false);
                    }
                }}
                closeIcon={<img src={close} />}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button
                            block
                            key="back"
                            onClick={() => {
                                if (form.isFieldsTouched() || changed) {
                                    setIsConfirmModal(true);
                                } else {
                                    setOpenModal(false);
                                }
                            }}
                            className="cancel"
                        >
                            Cancel
                        </Button>
                        <Button block key="submit" type="primary" onClick={handleFormSubmit}>
                            {isLoading ? <Loader /> : 'Save'}
                        </Button>
                    </div>
                }
            >
                <div className="modal">
                    <div className="modal__header meeting" style={{ height: '88px' }}>
                        <div className="modal__title">Define Phase Meeting</div>
                    </div>

                    <div className="modal__main main" style={{ padding: '24px 56px 43px 56px' }}>
                        <div className="main__subtitle" style={{ margin: '0 0 16px 0' }}>
                            Fields marked with <span style={{ color: '#E80A0F' }}>*</span> are mandatory
                        </div>
                        <Form form={form} onFinish={handleFormSubmit}>
                            <Form.Item name="date" rules={yupSync('date', validationSchema, true)}>
                                <DatePickerInput
                                    onChange={(data: Moment) => setDate(data)}
                                    value={date}
                                    required={true}
                                    placeholder=""
                                    disabled={false}
                                    floatLabel="Meeting Date"
                                    filterDisabled={false}
                                />
                            </Form.Item>
                            <div className="main__row for-phone">
                                <Form.Item
                                    name="time_from"
                                    className={`${screenWidth > 600 ? 'small-input-wrapper' : 'input-wrapper'}`}
                                    rules={yupSync('time_from', validationSchema, true)}
                                >
                                    <Select
                                        setChanged={setChanged}
                                        onChange={(data: Moment) => {
                                            setChangedData((prev) => !prev);
                                            setIsFirstRender(false);
                                            //@ts-ignore
                                            setStartTime(data);
                                        }}
                                        floatLabel="Time From"
                                        className="input"
                                        maxLength={60}
                                        required={true}
                                        hasError={() => !!form.getFieldError(['time_from']).length}
                                        options={timeArray}
                                        image={clock}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="time_to"
                                    className={`${screenWidth > 600 ? 'small-input-wrapper' : 'input-wrapper'}`}
                                    rules={yupSync('time_to', validationSchema, true)}
                                >
                                    <Select
                                        disabled={startTime || !isCreateModal ? false : true}
                                        setChanged={setChanged}
                                        floatLabel="Time To"
                                        className="input"
                                        maxLength={60}
                                        required={true}
                                        hasError={() => !!form.getFieldError(['time_to']).length}
                                        options={dependentArray}
                                        image={clock}
                                    />
                                </Form.Item>
                            </div>
                            <Form.Item name="link" rules={yupSync('link', validationSchema, true)}>
                                <FloatTextArea
                                    floatLabel={'Link to Questionnaire'}
                                    maxLength={1024}
                                    required={true}
                                    onKeyPress={handleKeyPress}
                                    hasError={() => !!form.getFieldError(['link']).length}
                                />
                            </Form.Item>
                            <Form.Item name="comment" rules={yupSync('comment', validationSchema, true)}>
                                <FloatTextArea
                                    floatLabel={'Comment'}
                                    maxLength={255}
                                    hasError={() => !!form.getFieldError(['comment']).length}
                                />
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>

            <Modal
                className="confirmModal"
                centered
                open={isConfirmModal}
                closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button key="back" onClick={() => setIsConfirmModal(false)} block className="cancel">
                            Cancel
                        </Button>
                        <button
                            className="quit"
                            onClick={() => {
                                setOpenModal(false);
                                setIsConfirmModal(false);
                                // refetch();
                            }}
                            // block
                        >
                            Quit
                        </button>
                    </div>
                }
            >
                <div className="modal-confirm">Are you sure you want to abandon the meeting setup?</div>
            </Modal>
        </>
    );
};
