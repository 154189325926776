import { FC, useState } from 'react';
import { Button, Form, Modal } from 'antd';
import { yupSync } from '../../utils';
import { FloatTextArea } from '../Fields/FloatTextArea';
import { useCancelTenderMutation, useDeclineTaskMutation } from '../../api/tenders';
import { Loader } from '../Loader/Loader';
import { tenderDataProps } from '../../pages/TenderViewPage/types';
import i from '../../assets/i.svg';

import close from '../../assets/RoundedClose.svg';

import * as Yup from 'yup';
import { PhaseI } from '../../api/types/common';

interface ModalProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    selectedTender: PhaseI | null;
}

interface commentData {
    comment: string | null;
}

const validationSchema = Yup.object().shape({
    comment: Yup.string()
        .required('Cancelation reasons field is required')
        .test('comment', 'This is a required field', (value) => {
            const passwordRegex = /[^\s]/;
            return passwordRegex.test(value);
        })
});

export const CancelTenderModal: FC<ModalProps> = ({ openModal, setOpenModal, setTrigger, selectedTender }) => {
    const [form] = Form.useForm<FormData>();
    const [isConfirmModal, setIsConfirmModal] = useState(false);
    const [cancelTender, { isLoading }] = useCancelTenderMutation();

    const cancelTenderFunc = async (props: commentData) => {
        try {
            const formData = {
                tenderId: selectedTender?.id,
                comment: props.comment
            };
            await cancelTender(formData);
            await setOpenModal(false);
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: any) => {
                if (isLoading) return;
                cancelTenderFunc(values);
            })
            .catch(() => {
                return;
            });
    };

    return (
        <>
            <Modal
                className="modal-modal"
                destroyOnClose
                centered
                open={openModal}
                onOk={() => handleFormSubmit()}
                onCancel={() => {
                    if (form.isFieldsTouched()) {
                        setIsConfirmModal(true);
                    } else {
                        setOpenModal(false);
                    }
                }}
                closeIcon={<img src={close} />}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button
                            block
                            key="back"
                            onClick={() => {
                                if (form.isFieldsTouched()) {
                                    setIsConfirmModal(true);
                                } else {
                                    setOpenModal(false);
                                }
                            }}
                            className="cancel"
                        >
                            No
                        </Button>
                        <Button block key="submit" type="primary" onClick={handleFormSubmit}>
                            {isLoading ? <Loader /> : 'Yes, Cancel'}
                        </Button>
                    </div>
                }
            >
                <div className="modal">
                    <div className="modal__header meeting" style={{ height: '88px' }}>
                        <div className="modal__title">Cancel Tender</div>
                    </div>

                    <div className="modal__main main" style={{ padding: '24px 56px 43px 56px' }}>
                        <div className="main__minititle">
                            Are you sure, you want to cancel{' '}
                            <span style={{ color: '#001965' }}>“{selectedTender?.number}”</span>?
                        </div>
                        <div className="main__attention">
                            <img src={i} />
                            After canceling the tender will be moved to the archive
                        </div>
                        <Form form={form} onFinish={handleFormSubmit}>
                            <Form.Item name="comment" rules={yupSync('comment', validationSchema, true)}>
                                <FloatTextArea
                                    disabled={false ? true : false}
                                    floatLabel={'Provide a reason for the cancellation'}
                                    maxLength={512}
                                    hasError={() => !!form.getFieldError(['comment']).length}
                                />
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>

            <Modal
                className={`confirmModal`}
                centered
                open={isConfirmModal}
                closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button key="back" onClick={() => setIsConfirmModal(false)} block className="cancel">
                            Cancel
                        </Button>
                        <button
                            className="quit"
                            onClick={() => {
                                setOpenModal(false);
                                setIsConfirmModal(false);
                            }}
                        >
                            Quit
                        </button>
                    </div>
                }
            >
                <div className="modal-confirm">Your data won’t be saved! Are you sure you want to quit?</div>
            </Modal>
        </>
    );
};
