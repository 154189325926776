import { FC, useState, useEffect, SetStateAction } from 'react';
import { Button, Calendar, Divider, Form, Modal, Tooltip, UploadFile } from 'antd';
import { Select } from '../Fields/Select';
import calendar from '../../assets/calendar-chedule.svg';

import { FloatInput } from '../Fields/FloatInput';
import { yupSync } from '../../utils';
import { FloatTextArea } from '../Fields/FloatTextArea';
import { useCreateSupportingDocumentMutation, useUpdateTaskMutation } from '../../api/tenders';
import { Loader } from '../Loader/Loader';
import { PartI, SlotsI, tenderDataProps } from '../../pages/TenderViewPage/types';
import clock from '../../assets/clock.svg';

import close from '../../assets/RoundedClose.svg';
import { createAbortController, UpdateTaskFile } from '../../api/axiosCruds';

import * as Yup from 'yup';
import { DatePickerInput } from '../Fields/DatePickerInput';
import moment, { Moment } from 'moment';
import { FilePicker } from '../Fields/FilePicker';

interface MessageApi {
    success: (message: string) => void;
    error: (message: string) => void;
}

interface ModalProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    messageApi?: MessageApi;
    data?: tenderDataProps;
    taskId: number | null;
    isEditSupplier: boolean;
}

interface ParticipantI {
    title: string;
    full_name: string;
}

interface FormDataI {
    date: string;
    time: string;
    file: any;
    comment: string;
    participants: ParticipantI[];
}

interface OptionI {
    id: string;
    value: string;
}

const validationSchema = Yup.object().shape({
    date: Yup.object().required('This is a required field'),
    time: Yup.object().required('This is a required field')
});

export const SupplierInfoModal: FC<ModalProps> = ({
    openModal,
    setOpenModal,
    messageApi,
    data,
    setTrigger,
    taskId,
    isEditSupplier
}) => {
    const [formData, setFormData] = useState<FormDataI>({
        date: '',
        time: '',
        file: [],
        comment: '',
        participants: []
    });
    const [availableDateSlots, setAvailableDateSlots] = useState<OptionI[]>([]);
    const [availableTimeSlots, setAvailableTimeSlots] = useState<OptionI[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState('');

    const [abortController, setAbortController] = useState<AbortController | null>(null);

    const [errors, setErrors] = useState<number[]>([]);
    const [isDirty, setIsDirty] = useState(false);
    const [fileErr, setFileErr] = useState('');

    const [form] = Form.useForm<FormData>();
    const [isConfirmModal, setIsConfirmModal] = useState(false);
    const [representativeCount, setRepresentativeCount] = useState<number>();

    useEffect(() => {
        if (!data) return;
        const element = data?.phase_tasks.find((item: any) => item.id === taskId);
        if (element && representativeCount) {
            setName(element.name);

            if (isEditSupplier) {
                let partArray: any = [];
                if (element?.participants) {
                    partArray = [...element.participants];
                }

                if (partArray.length < representativeCount) {
                    const difference = representativeCount - partArray.length;
                    for (let i = 0; i < difference; i++) {
                        partArray.push({ title: '', full_name: '' });
                    }
                }

                const prevData = {
                    date: element.meeting_slot[0].date,
                    time: `${element.meeting_slot[0].time_from} - ${element.meeting_slot[0].time_to}`,
                    file: element.files.tender_representative?.[0].name
                        ? [{ name: element.files.tender_representative?.[0].name }]
                        : [],
                    comment: element.comments.tender_representative,
                    participants: partArray
                };

                form.setFieldsValue({
                    //@ts-ignore
                    date: { id: element.meeting_slot[0].date, value: element.meeting_slot[0].date },
                    time: {
                        id: `${element.meeting_slot[0].time_from} - ${element.meeting_slot[0].time_to}`,
                        value: `${element.meeting_slot[0].time_from} - ${element.meeting_slot[0].time_to}`
                    },
                    comment: element.comments.tender_representative
                });

                setFormData(prevData);
            } else {
                setFormData((prev: FormDataI) => ({
                    ...formData,
                    participants: Array(representativeCount)
                        .fill(1)
                        .map((elem: any) => ({ title: '', full_name: '' }))
                }));
            }
        }
    }, [data, taskId, isEditSupplier, representativeCount]);

    const cancelRequest = () => {
        if (abortController) {
            abortController.abort();
        }
    };

    useEffect(() => {
        if (!data) return;
        setRepresentativeCount(data.supplier_task.representative_count);
    }, [data]);

    useEffect(() => {
        if (formData?.date && data) {
            const timeSlots: OptionI[] = [];
            const slots: SlotsI[] = data.supplier_task.available_meeting_slots;
            const date: string = formData.date;

            //@ts-ignore
            const element: SlotsI[] = slots[date];

            element?.forEach((item: SlotsI) => {
                timeSlots.push({
                    id: `${item.time_from} - ${item.time_to}`,
                    value: `${item.time_from} - ${item.time_to}`
                });
            });
            setAvailableTimeSlots(timeSlots);
        }
    }, [formData.date, data]);

    useEffect(() => {
        if (!data) return;
        const dateSlots = [];
        const slots = data.supplier_task.available_meeting_slots;
        for (let key in slots) {
            dateSlots.push({ id: key, value: moment(key).format('DD MMMM YYYY') });
        }
        setAvailableDateSlots(dateSlots);
    }, [data]);

    const createSupplierInfoFunc = async () => {
        setIsLoading(true);
        const abortController = createAbortController();

        const SupplierInfo = new FormData();
        SupplierInfo.append('_method', 'PUT');
        SupplierInfo.append('date', formData.date);
        SupplierInfo.append('time_from', formData.time.split('-')[0]?.trim());
        SupplierInfo.append('time_to', formData.time.split('-')[1]?.trim());

        if (formData.file[0]?.originFileObj) {
            SupplierInfo.append('file', formData.file[0].originFileObj);
        }
        if (!formData.file.length) {
            //@ts-ignore
            SupplierInfo.append('file', '');
        }
        if (formData.comment?.trim()) {
            SupplierInfo.append('comment', formData.comment?.trim());
        }
        let count = 0;

        formData.participants.forEach((item: ParticipantI) => {
            if (item.title.trim() || item.full_name.trim()) {
                SupplierInfo.append(`participants[${count}][title]`, item.title.trim());
                SupplierInfo.append(`participants[${count}][full_name]`, item.full_name.trim());
                count = count + 1;
            }
        });

        if (abortController) {
            abortController.abort();
        }

        const newAbortController = createAbortController();
        setAbortController(newAbortController);

        const signal = newAbortController.signal;

        const formDatatoSend = {
            ids: {
                tender_id: data?.id,
                task_id: taskId
            },
            data: SupplierInfo
        };
        try {
            await UpdateTaskFile(formDatatoSend, signal);
            await setOpenModal(false);
            await setTrigger((prev: boolean) => !prev);
        } catch {
        } finally {
            setIsLoading(false);
        }
    };

    const scrollToElementById = (elementId: string) => {
        const element = document.getElementById(elementId);

        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest'
            });
        }
    };

    const handleFormSubmit = () => {
        let isValid = true;

        let errorArray = [...errors];
        formData.participants.forEach((elem: ParticipantI, index: number) => {
            if (index === 0) {
                if (!elem.title.trim() || !elem.full_name.trim()) {
                    if (errorArray.includes(index)) {
                        isValid = false;
                    } else {
                        errorArray.push(index);
                        isValid = false;
                    }
                } else {
                    errorArray = errorArray.filter((item: number) => item !== index);
                }
            } else {
                if ((elem.title.trim() && !elem.full_name.trim()) || (!elem.title.trim() && elem.full_name.trim())) {
                    if (errorArray.includes(index)) {
                        isValid = false;
                    } else {
                        errorArray.push(index);
                        isValid = false;
                    }
                } else {
                    errorArray = errorArray.filter((item: number) => item !== index);
                }
            }
        });
        if (!isValid) {
            scrollToElementById('participants');
        }
        if (fileErr) {
            isValid = false;
        }
        setErrors(errorArray);
        form.validateFields()
            .then((values: any) => {
                if (isValid) {
                    if (isLoading) return;
                    createSupplierInfoFunc();
                }
            })
            .catch((err) => {
                err.errorFields.forEach((item: any) => {
                    if (item.errors.length && (item.name[0] === 'date' || item.name[0] === 'time')) {
                        scrollToElementById('date');
                    }
                });
            });
    };

    const handleInputChange = (index: number, value: string, name: string) => {
        setIsDirty(true);
        setErrors((prev: number[]) => {
            const filteredArray: number[] = prev.filter((item: number) => item !== index);
            return filteredArray;
        });
        setFormData((prevState: FormDataI) => {
            let updatedData = prevState.participants.map((participant, i) => {
                if (i === index) {
                    return { ...participant, [name]: value };
                }
                return participant;
            });

            return { ...prevState, participants: updatedData };
        });
    };

    return (
        <>
            <Modal
                className="modal-supplier"
                destroyOnClose
                centered
                open={openModal}
                onOk={() => handleFormSubmit()}
                onCancel={() => {
                    if (isDirty) {
                        setIsConfirmModal(true);
                    } else {
                        setOpenModal(false);
                    }
                }}
                closeIcon={<img src={close} />}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button
                            block
                            key="back"
                            onClick={() => {
                                if (isDirty) {
                                    setIsConfirmModal(true);
                                } else {
                                    setOpenModal(false);
                                }
                            }}
                            className="cancel"
                        >
                            Cancel
                        </Button>
                        <Button block key="submit" type="primary" onClick={handleFormSubmit}>
                            {isLoading ? <Loader /> : 'Save'}
                        </Button>
                    </div>
                }
            >
                <div className="modal">
                    <div className="modal__header meeting" style={{ height: '88px' }}>
                        <div className="modal__title name-title">
                            <Tooltip title={name}>{name}</Tooltip>
                        </div>
                    </div>

                    <div className="modal__main main" style={{ padding: '24px 56px 43px 56px' }}>
                        <div className="main__subtitle" style={{ margin: '0 0 6px 0' }}>
                            Fields marked with <span style={{ color: '#E80A0F' }}>*</span> are mandatory
                        </div>
                        <div className="main__subtitle" style={{ margin: '0 0 16px 0', fontSize: '16px' }}>
                            Select a slot for supplier pitches
                        </div>
                        <Form form={form} onFinish={handleFormSubmit} onChange={() => setIsDirty(true)}>
                            <div className="slot__row" id="date">
                                <Form.Item
                                    name="date"
                                    rules={yupSync('date', validationSchema, true)}
                                    className="input-wrapper pitch-time"
                                    style={{ height: '50px' }}
                                >
                                    <Select
                                        onChange={(value: any) => {
                                            setFormData((prev: FormDataI) => ({ ...prev, date: value?.id, time: '' }));
                                            form.setFieldValue('time', undefined);
                                        }}
                                        value={formData.date}
                                        floatLabel="Meeting Date"
                                        className="input"
                                        maxLength={60}
                                        required={true}
                                        hasError={() => !!form.getFieldError(['date']).length}
                                        options={availableDateSlots}
                                        image={calendar}
                                        setChanged={() => false}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="time"
                                    className="input-wrapper pitch-time"
                                    rules={yupSync('time', validationSchema, true)}
                                    style={{ height: '50px' }}
                                >
                                    <Select
                                        onChange={(value: any) => {
                                            setFormData((prev: FormDataI) => ({ ...prev, time: value?.id }));
                                        }}
                                        value={formData.time}
                                        floatLabel="Time Slot"
                                        className="input"
                                        maxLength={60}
                                        required={true}
                                        hasError={() => !!form.getFieldError(['time']).length}
                                        options={availableTimeSlots}
                                        image={clock}
                                        setChanged={() => false}
                                        disabled={formData.date ? false : true}
                                    />
                                </Form.Item>
                            </div>
                            <Divider />
                            <div className="main__subtitle" style={{ margin: '0 0 16px 0', fontSize: '16px' }}>
                                Provide information about supplier participants’{' '}
                            </div>

                            {Array(representativeCount)
                                ?.fill(1)
                                ?.map((_elem: any, index: number) => {
                                    return (
                                        <>
                                            <div
                                                className={`slot ${errors.includes(index) ? 'error' : ''}`}
                                                id="participants"
                                            >
                                                <div className="slot__title">Participant {index + 1}</div>
                                                <Form className="slot__form">
                                                    <Form.Item className="input-wrapper">
                                                        <FloatInput
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                                handleInputChange(index, e.target.value, 'title')
                                                            }
                                                            floatLabel={'Title'}
                                                            required={index === 0 ? true : false}
                                                            className="input"
                                                            maxLength={60}
                                                            value={formData.participants[index]?.title}
                                                        />
                                                    </Form.Item>

                                                    <Form.Item className="input-wrapper">
                                                        <FloatInput
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                                handleInputChange(index, e.target.value, 'full_name')
                                                            }
                                                            floatLabel={'Full Name'}
                                                            required={index === 0 ? true : false}
                                                            className="input"
                                                            maxLength={128}
                                                            value={formData.participants[index]?.full_name}
                                                        />
                                                    </Form.Item>
                                                </Form>
                                            </div>
                                            {errors.includes(index) && (
                                                <div className="error-text" style={{ margin: '-15px 0 10px 0' }}>
                                                    {index === 0
                                                        ? 'These are required fields'
                                                        : 'Please, specify Title and Full Name'}
                                                </div>
                                            )}
                                        </>
                                    );
                                })}

                            <Divider />

                            <Form.Item name="file">
                                <div className="main__pitchtitle">Copy of a suppliers’ presentation</div>
                                <FilePicker
                                    onChange={(file: any) => {
                                        setFormData((prev: FormDataI) => ({ ...prev, file: file }));
                                        setIsDirty(true);
                                    }}
                                    disabled={isLoading ? true : false}
                                    fileList={formData.file}
                                    error={fileErr}
                                    setError={setFileErr}
                                    specialFormats={['ppt', 'pdf', 'pptx']}
                                />
                            </Form.Item>
                            <Form.Item name="comment">
                                <FloatTextArea
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setFormData((prev: FormDataI) => ({ ...prev, comment: e.target.value }));
                                    }}
                                    value={formData.comment}
                                    floatLabel={'Comment'}
                                    maxLength={255}
                                    hasError={() => !!form.getFieldError(['comment']).length}
                                />
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>
            <Modal
                className="confirmModal"
                centered
                open={isConfirmModal}
                closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button key="back" onClick={() => setIsConfirmModal(false)} block className="cancel">
                            Cancel
                        </Button>
                        <button
                            className="quit"
                            onClick={() => {
                                cancelRequest();
                                setOpenModal(false);
                                setIsConfirmModal(false);
                            }}
                        >
                            Quit
                        </button>
                    </div>
                }
            >
                <div className="modal-confirm">Your data won’t be saved! Are you sure you want to quit?</div>
            </Modal>
        </>
    );
};
