import React, { useEffect } from 'react';
import { Tabs } from 'antd';
import { ManageUsers } from './ManageUsers';
import { useNavigate } from 'react-router-dom';
import { ManageSchools } from './ManageSchools';
import { useAppSelector } from '../../hooks';

import './index.scss';

const items = [
    {
        key: '1',
        label: `Manage users`,
        children: <ManageUsers />
    },
    {
        key: '2',
        label: `Manage schools`,
        children: <ManageSchools />
    },
    {
        key: '3',
        label: `Expenses area`,
        children: `Expenses area`
    }
];

export const AdminPanel = () => {
    const { role } = useAppSelector((state) => state.profileReducer);
    const navigate = useNavigate();

    useEffect(() => {
        if (!role) return;
        if (role !== 'super_admin') {
            navigate('/page-not-found');
        }
    }, [role]);

    return (
        <div className="admin-panel">
            <div className="admin-panel__nav nav">
                <div className="nav__title">Admin panel</div>
                <Tabs defaultActiveKey="1" items={items} />
            </div>
        </div>
    );
};
