import { FC, useState, useEffect } from 'react';
import { Button, Form, Modal } from 'antd';
import { FloatInput } from '../Fields/FloatInput';
import { yupSync } from '../../utils';
import { FloatTextArea } from '../Fields/FloatTextArea';
import { useCreateSupportingDocumentMutation } from '../../api/tenders';
import { Loader } from '../Loader/Loader';
import { tenderDataProps } from '../../pages/TenderViewPage/types';

import close from '../../assets/RoundedClose.svg';

import * as Yup from 'yup';

interface MessageApi {
    success: (message: string) => void;
    error: (message: string) => void;
}

interface ModalProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    messageApi?: MessageApi;
    data?: tenderDataProps;
}

interface FormDatatoSend {
    comment: string | null;
    document_name: string;
    action: string;
}

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required('This is a required field')
        .test('name', 'This is a required field', (value) => {
            const passwordRegex = /[^\s]/;
            return passwordRegex.test(value);
        }),
    comment: Yup.string().nullable()
});

export const SupportingModal: FC<ModalProps> = ({ openModal, setOpenModal, messageApi, data, setTrigger }) => {
    const [createSupportingDoc, { data: createSupportingDocData, isSuccess, isError, isLoading }] =
        useCreateSupportingDocumentMutation();

    const [form] = Form.useForm<FormData>();
    const [isConfirmModal, setIsConfirmModal] = useState(false);

    useEffect(() => {
        if (isSuccess) {
            messageApi?.success(createSupportingDocData.message);
        }
        if (isError) {
            messageApi?.error('Something went wrong, please try again');
        }
    }, [isSuccess, isError]);

    const createSupportingDocFunc = async (props: FormDatatoSend) => {
        const formData = {
            ids: {
                tenderId: data?.id
            },
            data: props
        };
        try {
            await createSupportingDoc(formData).unwrap();
            await setOpenModal(false);
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: any) => {
                const formData: FormDatatoSend = {
                    action: 'define_supporting_document',
                    document_name: values.name.trim(),
                    comment: values.comment ? values.comment.trim() : null
                };
                createSupportingDocFunc(formData);
            })
            .catch(() => {});
    };

    return (
        <>
            <Modal
                className="modal-modal"
                destroyOnClose
                centered
                open={openModal}
                onOk={() => handleFormSubmit()}
                onCancel={() => {
                    if (form.isFieldsTouched()) {
                        setIsConfirmModal(true);
                    } else {
                        setOpenModal(false);
                    }
                }}
                closeIcon={<img src={close} />}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button
                            block
                            key="back"
                            onClick={() => {
                                if (form.isFieldsTouched()) {
                                    setIsConfirmModal(true);
                                } else {
                                    setOpenModal(false);
                                }
                            }}
                            className="cancel"
                        >
                            Cancel
                        </Button>
                        <Button block key="submit" type="primary" onClick={handleFormSubmit}>
                            {isLoading ? <Loader /> : 'Save'}
                        </Button>
                    </div>
                }
            >
                <div className="modal">
                    <div className="modal__header meeting" style={{ height: '88px' }}>
                        <div className="modal__title">Supporting Document</div>
                    </div>

                    <div className="modal__main main" style={{ padding: '24px 56px 43px 56px' }}>
                        <Form form={form} onFinish={handleFormSubmit}>
                            <Form.Item
                                name="name"
                                className="input-wrapper"
                                rules={yupSync('name', validationSchema, true)}
                            >
                                <FloatInput floatLabel={'Document Name'} required className="input" maxLength={60} />
                            </Form.Item>
                            <Form.Item name="comment" rules={yupSync('comment', validationSchema, true)}>
                                <FloatTextArea
                                    floatLabel={'Comment'}
                                    maxLength={255}
                                    hasError={() => !!form.getFieldError(['comment']).length}
                                />
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>
            <Modal
                className="confirmModal"
                centered
                open={isConfirmModal}
                closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button key="back" onClick={() => setIsConfirmModal(false)} block className="cancel">
                            Cancel
                        </Button>
                        <button
                            className="quit"
                            onClick={() => {
                                setOpenModal(false);
                                setIsConfirmModal(false);
                            }}
                        >
                            Quit
                        </button>
                    </div>
                }
            >
                <div className="modal-confirm">Your data won’t be saved! Are you sure you want to quit?</div>
            </Modal>
        </>
    );
};
