import { Button, Form, Modal } from 'antd';
import { FC, useState, useEffect } from 'react';
import { FloatInput } from '../Fields/FloatInput';
import { yupSync } from '../../utils';
import { FloatTextArea } from '../Fields/FloatTextArea';
import { Loader } from '../Loader/Loader';
import { UploadFile } from 'antd/lib/upload/interface';
import { tenderDataProps } from '../../pages/TenderViewPage/types';
import { FilePicker } from '../Fields/FilePicker';
import { createAbortController, createDocument, UpdateTaskFile } from '../../api/axiosCruds';

import close from '../../assets/RoundedClose.svg';

import * as Yup from 'yup';

interface MessageApi {
    success: (message: string) => void;
    error: (message: string) => void;
}

interface ModalProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    messageApi?: MessageApi;
    type?: string;
    data?: tenderDataProps;
    taskId?: number | null | string;
}

interface FormDatatoSend {
    name: any;
    comment: string | null;
    document_name: string;
    action: string;
}

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required('This is a required field')
        .test('name', 'This is a required field', (value) => {
            const passwordRegex = /[^\s]/;
            return passwordRegex.test(value);
        }),
    file: Yup.array().of(Yup.object().shape({})).required('')
});

export const NewDocumentModal: FC<ModalProps> = ({
    openModal,
    setOpenModal,
    messageApi,
    type = 'create',
    data,
    setTrigger,
    taskId
}) => {
    const [abortController, setAbortController] = useState<AbortController | null>(null);

    const [fileErr, setFileErr] = useState('');
    const [file, setFile] = useState<UploadFile[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState('');

    const [form] = Form.useForm<FormData>();
    const [isConfirmModal, setIsConfirmModal] = useState(false);

    useEffect(() => {
        if (type === 'update') {
            if (!taskId || !data) return;
            const element = data.phase_tasks.find((elem) => elem.id === taskId);

            if (element) {
                form.setFieldsValue({
                    //@ts-ignore
                    name: element?.name
                });
                setName(element.name);
            }
        }
    }, [taskId, taskId]);

    const cancelRequest = () => {
        if (abortController) {
            abortController.abort();
        }
    };

    const createOrUpdateNewDocumentFunc = async (values: FormDatatoSend) => {
        const abortController = createAbortController();

        if (file[0] && (!fileErr || fileErr === 'Upload failed. Check your internet connection')) {
            const contentFile: any = file[0]?.originFileObj;
            const res: any = new FormData();

            if (type === 'create') {
                res.append('action', 'design_document');
            } else {
                res.append('_method', 'PUT');
            }

            res.append('file', contentFile, file[0].name);
            res.append('document_name', values.name.trim());
            res.append('comment', values.comment ? values.comment : '');
            let formData = {};

            setFileErr('');

            if (abortController) {
                abortController.abort();
            }

            const newAbortController = createAbortController();
            setAbortController(newAbortController);

            const signal = newAbortController.signal;
            setIsLoading(true);
            try {
                if (type === 'create') {
                    formData = {
                        ids: {
                            tenderId: data?.id
                        },
                        data: res
                    };
                    await createDocument(formData, signal);
                }
                if (type === 'update') {
                    formData = {
                        ids: {
                            tender_id: data?.id,
                            task_id: taskId
                        },
                        data: res
                    };
                    await UpdateTaskFile(formData, signal);
                }

                await setTrigger((prev: boolean) => !prev);
                setOpenModal(false);
                await messageApi?.success('The task has been created successfully');
            } catch {
                messageApi?.error('Something went wrong, please try again');
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleFormSubmit = () => {
        let isValid = true;
        if (!file.length) {
            isValid = false;
            setFileErr('You can upload files in the following formats: doc, pdf with a maximum size of 100MB');
        }

        form.validateFields()
            .then((values: any) => {
                createOrUpdateNewDocumentFunc(values);
            })
            .catch(() => {});
    };

    return (
        <>
            <Modal
                className="modal-modal"
                destroyOnClose
                centered
                open={openModal}
                onOk={() => handleFormSubmit()}
                onCancel={() => {
                    if (form.isFieldsTouched()) {
                        setIsConfirmModal(true);
                    } else {
                        setOpenModal(false);
                    }
                }}
                closeIcon={<img src={close} />}
                // width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button
                            block
                            key="back"
                            onClick={() => {
                                if (form.isFieldsTouched()) {
                                    setIsConfirmModal(true);
                                } else {
                                    setOpenModal(false);
                                }
                            }}
                            className="cancel"
                        >
                            Cancel
                        </Button>
                        <Button block key="submit" type="primary" onClick={isLoading ? () => {} : handleFormSubmit}>
                            {isLoading ? <Loader /> : 'Save'}
                        </Button>
                    </div>
                }
            >
                <div className="modal">
                    <div className="modal__header meeting" style={{ height: '88px' }}>
                        <div className="modal__title">{type === 'update' ? 'Resend Document' : 'New Document'}</div>
                    </div>

                    <div className="modal__main main" style={{ padding: '24px 56px 43px 56px' }}>
                        <Form form={form} onFinish={handleFormSubmit}>
                            <Form.Item
                                name="name"
                                className="input-wrapper"
                                rules={yupSync('name', validationSchema, true)}
                            >
                                <FloatInput
                                    disabled={name ? true : false}
                                    floatLabel={'Document Name'}
                                    required
                                    className="input"
                                    maxLength={60}
                                    value={name}
                                />
                            </Form.Item>
                            <div className="helper-text">Upload a file *</div>
                            <Form.Item name="file" rules={yupSync('file', validationSchema, true)}>
                                <FilePicker
                                    disabled={isLoading ? true : false}
                                    fileList={file}
                                    onChange={setFile}
                                    error={fileErr}
                                    setError={setFileErr}
                                    title="Doc, Pdf, Zip, Xls, Xlsx (max. 100 MB)"
                                    specialFormats={['doc', 'docx', 'pdf', 'zip', 'xls', 'xlsx']}
                                />
                            </Form.Item>
                            <Form.Item name="comment">
                                <FloatTextArea floatLabel={'Comment'} maxLength={255} hasError={() => {}} />
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>
            <Modal
                className="confirmModal"
                centered
                open={isConfirmModal}
                closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button key="back" onClick={() => setIsConfirmModal(false)} block className="cancel">
                            Cancel
                        </Button>
                        <button
                            className="quit"
                            onClick={() => {
                                cancelRequest();
                                setOpenModal(false);
                                setIsConfirmModal(false);
                            }}
                        >
                            Quit
                        </button>
                    </div>
                }
            >
                <div className="modal-confirm">Your data won’t be saved! Are you sure you want to quit?</div>
            </Modal>
        </>
    );
};
