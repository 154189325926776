import { FC, useState, useEffect } from 'react';
import { Button, Form, Modal } from 'antd';
import { yupSync } from '../../utils';
import { FloatTextArea } from '../Fields/FloatTextArea';
import { useUpdateTaskMutation } from '../../api/tenders';
import { Loader } from '../Loader/Loader';
import { tenderDataProps } from '../../pages/TenderViewPage/types';
import clock from '../../assets/clock.svg';

import close from '../../assets/RoundedClose.svg';

import * as Yup from 'yup';
import { usePreventSpaceTyping } from '../../hooks/usePreventSpaceTyping';
import { PrevData } from '../Phases/EvaluatePhase3Components/SupplierScorecard';
import { DatePickerInput } from '../Fields/DatePickerInput';
import { Select } from '../Fields/Select';
import moment from 'moment';

interface MessageApi {
    success: (message: string) => void;
    error: (message: string) => void;
}

interface FormDatatoSend {
    date: string;
    time_from: string;
    time_to: string;
    action: string;
}

interface ModalProps {
    openModal: boolean;
    isSuccess: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    setDeadlineModal: React.Dispatch<React.SetStateAction<boolean>>;
    messageApi?: MessageApi;
    data?: tenderDataProps;
    taskId: number | null | string;
}

interface TimeObject {
    value: string | null;
    id: number | null | string;
}

const validationSchema = Yup.object().shape({
    date: Yup.string().required('This is a required field'),
    time_from: Yup.object().required('This is a required field'),
    time_to: Yup.object().required('This is a required field')
});

export const NoShowModal: FC<ModalProps> = ({
    openModal,
    setOpenModal,
    messageApi,
    data,
    setTrigger,
    taskId,
    setDeadlineModal,
    isSuccess
}) => {
    const [form] = Form.useForm<FormData>();

    const [updateDeadlineModal, setIsUpdateDeadlineModal] = useState(false);
    const [isConfirmModal, setIsConfirmModal] = useState(false);

    const [updateTask, { data: updatedTaskData, isSuccess: isSuccessUpdateTask, isError, isLoading }] =
        useUpdateTaskMutation();
    const [name, setName] = useState('');
    const [timeFrom, setTimeFrom] = useState('');

    useEffect(() => {
        if (!data) return;
        const element = data?.phase_tasks.find((item: any) => item.id === taskId);
        if (element) {
            setName(element.name);
        }
    }, [data, taskId]);

    const timeArray: TimeObject[] = generateTimeArray();
    const [dependentArray, setDependentArray] = useState<TimeObject[]>([]);

    function generateTimeArray(): TimeObject[] {
        const timeArray: TimeObject[] = [
            { value: '07:00', id: '07:00' },
            { value: '07:15', id: '07:15' },
            { value: '07:30', id: '07:30' },
            { value: '07:45', id: '07:45' },
            { value: '08:00', id: '08:00' },
            { value: '08:15', id: '08:15' },
            { value: '08:30', id: '08:30' },
            { value: '08:45', id: '08:45' },
            { value: '09:00', id: '09:00' },
            { value: '09:15', id: '09:15' },
            { value: '09:30', id: '09:30' },
            { value: '09:45', id: '09:45' },
            { value: '10:00', id: '10:00' },
            { value: '10:15', id: '10:15' },
            { value: '10:30', id: '10:30' },
            { value: '10:45', id: '10:45' },
            { value: '11:00', id: '11:00' },
            { value: '11:15', id: '11:15' },
            { value: '11:30', id: '11:30' },
            { value: '11:45', id: '11:45' },
            { value: '12:00', id: '12:00' },
            { value: '12:15', id: '12:15' },
            { value: '12:30', id: '12:30' },
            { value: '12:45', id: '12:45' },
            { value: '13:00', id: '13:00' },
            { value: '13:15', id: '13:15' },
            { value: '13:30', id: '13:30' },
            { value: '13:45', id: '13:45' },
            { value: '14:00', id: '14:00' },
            { value: '14:15', id: '14:15' },
            { value: '14:30', id: '14:30' },
            { value: '14:45', id: '14:45' },
            { value: '15:00', id: '15:00' },
            { value: '15:15', id: '15:15' },
            { value: '15:30', id: '15:30' },
            { value: '15:45', id: '15:45' },
            { value: '16:00', id: '16:00' },
            { value: '16:15', id: '16:15' },
            { value: '16:30', id: '16:30' },
            { value: '16:45', id: '16:45' }
        ];
        return timeArray;
    }

    function generateDependentArray(baseTime: string | null) {
        const index = timeArray.findIndex((elem: TimeObject) => {
            return elem.value === baseTime;
        });
        const newArray = timeArray.slice(index + 1);
        newArray.push({ value: '17:00', id: '17:00' });
        return newArray;
    }

    useEffect(() => {
        if (timeFrom) {
            const dependentArray = generateDependentArray(timeFrom);
            setDependentArray(dependentArray);
        } else {
            form.setFieldValue('time_to', undefined);
        }
    }, [timeFrom]);

    useEffect(() => {
        if (isSuccessUpdateTask) {
            messageApi?.success(updatedTaskData?.data?.message);
            setOpenModal(false);
        }
        if (isError) {
            messageApi?.error('Something went wrong, please try again');
        }
    }, [isSuccessUpdateTask, isError]);

    const updateTaskFunc = async (props: FormDatatoSend) => {
        if (isLoading) return;
        const formData = {
            ids: {
                tender_id: data?.id,
                task_id: taskId
            },
            data: props
        };
        try {
            await updateTask(formData).unwrap();
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    const handleFormSubmit = (success: boolean = false) => {
        form.validateFields()
            .then((values: any) => {
                const formData: FormDatatoSend = {
                    date: moment(values.date).format('YYYY-MM-DD'),
                    time_from: values.time_from.id && values.time_from.id.split(' ')[0],
                    time_to: values.time_to.id && values.time_to.id.split(' ')[0],
                    action: 'supplier_no_show'
                };
                if (isSuccess || success) {
                    updateTaskFunc(formData);
                } else {
                    setIsUpdateDeadlineModal(true);
                }
            })
            .catch(() => {});
    };

    useEffect(() => {
        if (isSuccess) {
            handleFormSubmit();
        }
    }, [isSuccess]);

    return (
        <>
            <Modal
                className="modal-modal"
                destroyOnClose
                centered
                open={openModal}
                onOk={() => handleFormSubmit()}
                onCancel={() => {
                    if (form.isFieldsTouched()) {
                        setIsConfirmModal(true);
                    } else {
                        setOpenModal(false);
                    }
                }}
                closeIcon={<img src={close} />}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button
                            block
                            key="back"
                            onClick={() => {
                                if (form.isFieldsTouched()) {
                                    setIsConfirmModal(true);
                                } else {
                                    setOpenModal(false);
                                }
                            }}
                            className="cancel"
                        >
                            Cancel
                        </Button>
                        <Button block key="submit" type="primary" onClick={() => handleFormSubmit()}>
                            {isLoading ? <Loader /> : 'Save'}
                        </Button>
                    </div>
                }
            >
                <div className="modal">
                    <div className="modal__header meeting" style={{ height: '88px' }}>
                        <div className="modal__title name-title">{name} - No Show</div>
                    </div>

                    <div className="modal__main main" style={{ padding: '24px 56px 43px 56px' }}>
                        <div className="main__subtitle" style={{ margin: '0 0 6px 0' }}>
                            Fields marked with <span style={{ color: '#E80A0F' }}>*</span> are mandatory
                        </div>
                        <div className="main__subtitle" style={{ margin: '0 0 16px 0', fontSize: '16px' }}>
                            Provide New slot for supplier pitches{' '}
                        </div>
                        <Form form={form} onFinish={() => handleFormSubmit()}>
                            <Form.Item
                                className="input-wrapper"
                                name="date"
                                rules={yupSync('date', validationSchema, true)}
                            >
                                <DatePickerInput
                                    onChange={(data: string) => {}}
                                    value={''}
                                    required={true}
                                    placeholder=""
                                    disabled={false}
                                    floatLabel="Meeting Date"
                                    filterDisabled={false}
                                />
                            </Form.Item>
                            <div className="slot__row">
                                <Form.Item
                                    className="input-wrapper pitch-time"
                                    name="time_from"
                                    rules={yupSync('time_from', validationSchema, true)}
                                    style={{ height: '70px' }}
                                >
                                    <Select
                                        className="time-select"
                                        setChanged={() => {}}
                                        onChange={(data: any) => {
                                            setTimeFrom(data?.id);
                                        }}
                                        value={''}
                                        floatLabel="Time From"
                                        maxLength={60}
                                        required={true}
                                        hasError={() => !!form.getFieldError(['time_from']).length}
                                        options={timeArray}
                                        image={clock}
                                    />
                                </Form.Item>
                                <Form.Item
                                    className="input-wrapper pitch-time"
                                    name="time_to"
                                    rules={yupSync('time_to', validationSchema, true)}
                                    style={{ height: '70px' }}
                                >
                                    <Select
                                        disabled={timeFrom ? false : true}
                                        setChanged={() => {}}
                                        floatLabel="Time To"
                                        maxLength={60}
                                        required={true}
                                        hasError={() => !!form.getFieldError(['time_to']).length}
                                        options={dependentArray}
                                        image={clock}
                                    />
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                </div>
            </Modal>
            <Modal
                className="confirmModal"
                centered
                open={isConfirmModal}
                closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button key="back" onClick={() => setIsConfirmModal(false)} block className="cancel">
                            Cancel
                        </Button>
                        <button
                            className="quit"
                            onClick={() => {
                                setOpenModal(false);
                                setIsConfirmModal(false);
                            }}
                        >
                            Quit
                        </button>
                    </div>
                }
            >
                <div className="modal-confirm">Your data won’t be saved! Are you sure you want to quit?</div>
            </Modal>
            <Modal
                className={''}
                centered
                open={updateDeadlineModal}
                closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button
                            key="back"
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsUpdateDeadlineModal(false);
                                handleFormSubmit(true);
                            }}
                            block
                            className="cancel"
                        >
                            No
                        </Button>
                        <button
                            className="quit"
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsUpdateDeadlineModal(false);
                                setDeadlineModal(true);
                            }}
                        >
                            Yes
                        </button>
                    </div>
                }
            >
                <div className="modal-confirm">Would you like to update the Task Deadline?</div>
            </Modal>
        </>
    );
};
