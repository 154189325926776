import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Form, message, Modal, Row, Tooltip, Typography } from 'antd';
import { yupSync } from '../../../utils';
import { FloatInput } from '../../Fields/FloatInput';
import { usePreventSpaceTyping } from '../../../hooks/usePreventSpaceTyping';
import * as Yup from 'yup';
import { useAppSelector } from '../../../hooks';
import { logout } from '../../../reducers/auth/authSlice';
import { useForgotPasswordMutation, useLoginMutation } from '../../../api/auth';
import { useUpdatePasswordMutation } from '../../../api/profile';
import Lock from '../../../components/Icons/Lock';
import { useDispatch } from 'react-redux';

interface FormData {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
}

interface ChangePasswordProps {
    onClose: () => void;
    onOpen: () => void;
    open: boolean;
    disabled?: boolean;
}

export const ChangePassword = ({ onClose, open, onOpen, disabled = false }: ChangePasswordProps) => {
    const [form] = Form.useForm<FormData>();
    const { handleKeyPress } = usePreventSpaceTyping();
    const email = useAppSelector((state) => state.profileReducer.email);
    const [login, { isLoading: oldPasswordCheckLoading }] = useLoginMutation();
    const [changePassword, { isLoading }] = useUpdatePasswordMutation();
    const [forgotPassword, { isLoading: isLoadingForgotPassword, isSuccess: isSuccessForgotPassword }] =
        useForgotPasswordMutation();
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const [passwordErr, setPasswordErr] = useState(false);
    const dispatch = useDispatch();
    const [confirmModal, setConfirmModal] = useState(false);

    const [screenWidth, setScreenWidth] = useState<number>(window.screen.width);

    function handleResize() {
        setScreenWidth(window.screen.width);
    }

    window.addEventListener('resize', handleResize);

    const handleSubmit = async (values: FormData) => {
        try {
            await changePassword({
                old_password: values.oldPassword,
                password: values.newPassword,
                password_confirmation: values.confirmPassword
            }).unwrap();
            dispatch(logout());
            sessionStorage.removeItem('authUser');
            messageApi.success('Password changed successfully', 2, () => navigate('/login'));
        } catch (e) {
            messageApi.error('Something went wrong. Please try again later');
        }
    };

    const validationSchema = Yup.object().shape({
        newPassword: Yup.string()
            .required('Field is required')
            .test('password-requirements', '', (value) => {
                const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
                return passwordRegex.test(value);
            }),
        confirmPassword: Yup.string().required('Field is required')
    });

    const handleForgotPassword = async () => {
        try {
            await forgotPassword(email || '').unwrap();
            messageApi.info(`An email containing the password recovery link has been sent to you`);
        } catch (e: any) {
            if (e?.status === 429) {
                messageApi.error('You have exceeded the number of verification attempts. Please try again in 24 hours');
            } else {
                messageApi.error('Something went wrong, please try again later');
            }
        }
    };
    return (
        <>
            {contextHolder}
            <div className="profile">
                <div className="profile__block block">
                    <Typography style={{ fontWeight: 500, fontSize: '24px', color: '#001965' }}>Password</Typography>
                </div>
                <div className="profile__block block">
                    {open ? (
                        <Form form={form} id="cahnge-password-form" onFinish={handleSubmit}>
                            <Form.Item
                                style={{ marginBottom: 0 }}
                                name="oldPassword"
                                validateTrigger={['onBlur', 'onSubmit']}
                                rules={[
                                    { required: true, message: 'Please enter your old password' },
                                    {
                                        async validator(_, value) {
                                            try {
                                                if (value) await login({ email, password: value }).unwrap();
                                            } catch (e) {
                                                throw new Error(
                                                    'Oops! It looks like the password you entered is incorrect'
                                                );
                                            }
                                        }
                                    }
                                ]}
                            >
                                <FloatInput
                                    type="password"
                                    floatLabel="Old Password"
                                    className="input"
                                    onKeyPress={handleKeyPress}
                                    maxLength={32}
                                />
                            </Form.Item>
                            <div className="auth__forgot" style={{ margin: '0 0 16px' }}>
                                <Button
                                    style={{ paddingLeft: 0 }}
                                    type="link"
                                    onClick={() => handleForgotPassword()}
                                    disabled={isLoadingForgotPassword}
                                    loading={isLoadingForgotPassword}
                                >
                                    Forgot password?
                                </Button>
                            </div>
                            <Form.Item name="newPassword" rules={yupSync('newPassword', validationSchema, true)}>
                                <FloatInput
                                    type="password"
                                    floatLabel="New Password"
                                    placeholder="New Password(>8characters)"
                                    className="input"
                                    onKeyPress={handleKeyPress}
                                    maxLength={32}
                                    onBlur={() => {
                                        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
                                        const res = passwordRegex.test(form.getFieldValue('newPassword'));
                                        if (res || !form.getFieldValue('newPassword')) {
                                            setPasswordErr(false);
                                        } else {
                                            setPasswordErr(true);
                                        }
                                    }}
                                />
                            </Form.Item>
                            <div
                                className="profile__label"
                                style={passwordErr ? { color: '#e80a0f', margin: '-24px 0 16px 0' } : {}}
                            >
                                {`The password should contain numbers and at least one upper case and lower case letter (>8 characters)`}
                            </div>
                            <Form.Item
                                name="confirmPassword"
                                rules={[
                                    ...yupSync('confirmPassword', validationSchema),
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('newPassword') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Oops! Your passwords do not match'));
                                        }
                                    })
                                ]}
                                dependencies={['newPassword']}
                            >
                                <FloatInput
                                    type="password"
                                    floatLabel="Repeat New Password"
                                    placeholder="Repeat New Password(>8characters)"
                                    className="input"
                                    onKeyPress={handleKeyPress}
                                    maxLength={32}
                                />
                            </Form.Item>
                            <Row gutter={16} style={{ margin: '40px 0 0 0' }}>
                                <Col span={12}>
                                    <Button
                                        onClick={() => {
                                            if (form.isFieldsTouched()) {
                                                setConfirmModal(true);
                                            } else {
                                                form.resetFields();
                                                setPasswordErr(false);
                                                onClose();
                                            }
                                        }}
                                        className="cancel"
                                        block
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                                <Col span={12}>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        form="cahnge-password-form"
                                        loading={isLoading || oldPasswordCheckLoading}
                                        block
                                    >
                                        Save Changes
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    ) : (
                        <Tooltip
                            title="To change the password, you need to finish editing the profile info"
                            overlayClassName={` ${disabled ? 'custom-tooltip-disabled' : 'custom-tooltip'}`}
                        >
                            <Button
                                onClick={() => onOpen()}
                                icon={<Lock style={{ fontSize: '20px', marginTop: '-2px' }} />}
                                disabled={disabled}
                                style={screenWidth < 834 ? { width: '100%' } : {}}
                            >
                                Change Password
                            </Button>
                        </Tooltip>
                    )}
                </div>
                <div className="profile__block block"></div>
            </div>
            <Modal
                className="confirmModal"
                centered
                open={confirmModal}
                closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button key="back" onClick={() => setConfirmModal(false)} block className="cancel">
                            Cancel
                        </Button>
                        <button
                            className="quit"
                            onClick={() => {
                                setConfirmModal(false);
                                form.resetFields();
                                setPasswordErr(false);
                                onClose();
                            }}
                        >
                            Quit
                        </button>
                    </div>
                }
            >
                <div className="modal-confirm">Your data won’t be saved! Are you sure you want to quit?</div>
            </Modal>
        </>
    );
};
