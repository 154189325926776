import { FC, useState, useEffect } from 'react';
import { Button, Form, Modal } from 'antd';
import { yupSync } from '../../utils';
import { FloatTextArea } from '../Fields/FloatTextArea';
import { useUpdateTaskMutation } from '../../api/tenders';
import { Loader } from '../Loader/Loader';
import { tenderDataProps } from '../../pages/TenderViewPage/types';

import close from '../../assets/RoundedClose.svg';

import * as Yup from 'yup';
import { usePreventSpaceTyping } from '../../hooks/usePreventSpaceTyping';
import { PrevData } from '../Phases/EvaluatePhase3Components/SupplierScorecard';

interface MessageApi {
    success: (message: string) => void;
    error: (message: string) => void;
}

interface FormDatatoSend {
    comment: string | null;
    link: string;
    action?: string;
}

interface ModalProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    messageApi?: MessageApi;
    data?: tenderDataProps;
    taskId: number | null | string;
    prevData: PrevData;
}

const validationSchema = Yup.object().shape({
    link: Yup.string()
        .required('This is a required field')
        .matches(/^(https?:\/\/)/, 'Oops! Your link is not correct'),
    comment: Yup.string().nullable()
});

export const SupplierScorecardModal: FC<ModalProps> = ({
    openModal,
    setOpenModal,
    messageApi,
    data,
    setTrigger,
    taskId,
    prevData
}) => {
    const [updateTask, { data: updatedTaskData, isSuccess, isError, error, isLoading }] = useUpdateTaskMutation();
    const [isDirty, setIsDirty] = useState(false);

    const { handleKeyPress } = usePreventSpaceTyping();

    const [form] = Form.useForm<FormData>();
    const [isConfirmModal, setIsConfirmModal] = useState(false);

    useEffect(() => {
        if (isSuccess) {
            messageApi?.success(updatedTaskData?.message);
        }
        if (isError) {
            messageApi?.error('Something went wrong, please try again');
        }
    }, [isSuccess, isError]);

    useEffect(() => {
        if (prevData.link) {
            form.setFieldsValue({
                //@ts-ignore
                link: prevData.link,
                comment: prevData.comment
            });
        }
    }, [prevData]);

    const updateTaskFunc = async (props: FormDatatoSend) => {
        if (isLoading) return;
        const formData = {
            ids: {
                tender_id: data?.id,
                task_id: taskId
            },
            data: props
        };
        try {
            await updateTask(formData).unwrap();
            await setOpenModal(false);
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: any) => {
                const formData: FormDatatoSend = {
                    action: 'supplier_scorecard',
                    link: values.link.trim(),
                    comment: values.comment ? values.comment.trim() : null
                };
                updateTaskFunc(formData);
            })
            .catch(() => {});
    };

    return (
        <>
            <Modal
                className="modal-modal"
                destroyOnClose
                centered
                open={openModal}
                onOk={() => handleFormSubmit()}
                onCancel={() => {
                    if (isDirty) {
                        setIsConfirmModal(true);
                    } else {
                        setOpenModal(false);
                    }
                }}
                closeIcon={<img src={close} />}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button
                            block
                            key="back"
                            onClick={() => {
                                if (isDirty) {
                                    setIsConfirmModal(true);
                                } else {
                                    setOpenModal(false);
                                }
                            }}
                            className="cancel"
                        >
                            Cancel
                        </Button>
                        <Button block key="submit" type="primary" onClick={handleFormSubmit}>
                            {isLoading ? <Loader /> : 'Save'}
                        </Button>
                    </div>
                }
            >
                <div className="modal">
                    <div className="modal__header meeting" style={{ height: '88px' }}>
                        <div className="modal__title">Supplier Scorecard</div>
                    </div>

                    <div className="modal__main main" style={{ padding: '24px 56px 43px 56px' }}>
                        <Form form={form} onFinish={handleFormSubmit} onChange={() => setIsDirty(true)}>
                            <Form.Item name="link" rules={yupSync('link', validationSchema, true)}>
                                <FloatTextArea
                                    floatLabel={'Link to Questionnaire'}
                                    maxLength={1024}
                                    required={true}
                                    onKeyPress={handleKeyPress}
                                    hasError={() => !!form.getFieldError(['link']).length}
                                />
                            </Form.Item>
                            <Form.Item name="comment" rules={yupSync('comment', validationSchema, true)}>
                                <FloatTextArea
                                    floatLabel={'Comment'}
                                    maxLength={255}
                                    hasError={() => !!form.getFieldError(['comment']).length}
                                />
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>
            <Modal
                className="confirmModal"
                centered
                open={isConfirmModal}
                closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button key="back" onClick={() => setIsConfirmModal(false)} block className="cancel">
                            Cancel
                        </Button>
                        <button
                            className="quit"
                            onClick={() => {
                                setOpenModal(false);
                                setIsConfirmModal(false);
                            }}
                        >
                            Quit
                        </button>
                    </div>
                }
            >
                <div className="modal-confirm">Your data won’t be saved! Are you sure you want to quit?</div>
            </Modal>
        </>
    );
};
