import { FC, useState } from 'react';
import { Button, Form, Modal } from 'antd';
import { yupSync } from '../../utils';
import { FloatTextArea } from '../Fields/FloatTextArea';
import { Loader } from '../Loader/Loader';
import { UploadFile } from 'antd/lib/upload/interface';
import { tenderDataProps } from '../../pages/TenderViewPage/types';
import { FilePicker } from '../Fields/FilePicker';
import { declineTask } from '../../api/axiosCruds';
import { createAbortController } from '../../api/axiosCruds';

import close from '../../assets/RoundedClose.svg';

import * as Yup from 'yup';

interface ModalProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    type?: string;
    data: tenderDataProps;
    taskId: number | null | string;
    isCreateModal?: boolean;
}

interface commentData {
    comment: string | null;
}

const validationSchema = Yup.object().shape({
    comment: Yup.string()
        .required('Comment field is required')
        .test('comment', 'This is a required field', (value) => {
            const passwordRegex = /[^\s]/;
            return passwordRegex.test(value);
        })
});

export const RejectNegotiatedSupplyAgreement: FC<ModalProps> = ({
    openModal,
    setOpenModal,
    type,
    data,
    setTrigger,
    taskId
}) => {
    const [form] = Form.useForm<FormData>();
    const [isConfirmModal, setIsConfirmModal] = useState(false);
    const [file, setFile] = useState<UploadFile[]>([]);
    const [fileErr, setFileErr] = useState('');
    const [abortController, setAbortController] = useState<AbortController | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const cancelRequest = () => {
        if (abortController) {
            abortController.abort();
        }
    };

    const declineTaskFunc = async (prop: commentData) => {
        setIsLoading(true);
        try {
            const abortController = createAbortController();
            if (!fileErr || fileErr === 'Upload failed. Check your internet connection') {
                const contentFile: any = file[0]?.originFileObj;
                const res: any = new FormData();
                if (file[0]) {
                    res.append('file', contentFile, file[0].name);
                } else {
                }
                res.append('comment', prop.comment ? prop.comment : '');
                res.append('_method', 'PUT');

                const formData = {
                    ids: {
                        tenderId: data?.id,
                        taskId: taskId
                    },
                    data: res
                };
                setFileErr('');

                if (abortController) {
                    abortController.abort();
                }

                const newAbortController = createAbortController();
                setAbortController(newAbortController);

                const signal = newAbortController.signal;

                await declineTask(formData, signal);
                await setOpenModal(false);
                await setTrigger((prev: boolean) => !prev);
            }
        } catch {
        } finally {
            setIsLoading(false);
        }
    };

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: any) => {
                const data = {
                    comment: values.comment
                };
                declineTaskFunc(data);
            })
            .catch((err) => {});
    };

    return (
        <>
            <Modal
                className="modal-modal"
                destroyOnClose
                centered
                open={openModal}
                onOk={() => handleFormSubmit()}
                onCancel={() => {
                    if (form.isFieldsTouched()) {
                        setIsConfirmModal(true);
                    } else {
                        setOpenModal(false);
                    }
                }}
                closeIcon={<img src={close} />}
                // width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button
                            block
                            key="back"
                            onClick={() => {
                                if (form.isFieldsTouched()) {
                                    setIsConfirmModal(true);
                                } else {
                                    setOpenModal(false);
                                }
                            }}
                            className="cancel"
                        >
                            Cancel
                        </Button>
                        <Button block key="submit" type="primary" onClick={handleFormSubmit} loading={isLoading}>
                            {isLoading ? <Loader /> : 'Confirm'}
                        </Button>
                    </div>
                }
            >
                <div className="modal">
                    <div className="modal__header meeting" style={{ height: '88px' }}>
                        <div className="modal__title">Reject Negotiated Supply Agreement?</div>
                    </div>

                    <div className="modal__main main" style={{ padding: '24px 56px 43px 56px' }}>
                        <div className="main__title">
                            Specify any sections of the agreement you wish to change and provide details on the desired
                            revisions.
                        </div>
                        <Form form={form} onFinish={handleFormSubmit}>
                            <Form.Item name="comment" rules={yupSync('comment', validationSchema, true)}>
                                <FloatTextArea
                                    disabled={isLoading ? true : false}
                                    floatLabel={
                                        <span>
                                            Comment <span style={{ color: '#E80A0F' }}>*</span>
                                        </span>
                                    }
                                    maxLength={512}
                                    hasError={() => !!form.getFieldError(['comment']).length}
                                />
                            </Form.Item>
                            <div className="main__subtitle" style={{ margin: '0 0 5px 0' }}>
                                Upload a file
                            </div>
                            <Form.Item name="file">
                                <FilePicker
                                    disabled={isLoading ? true : false}
                                    fileList={file}
                                    onChange={setFile}
                                    error={fileErr}
                                    setError={setFileErr}
                                    specialFormats={['pdf', 'doc', 'docx', 'zip']}
                                />
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>

            <Modal
                className={`${type === 'view' || type === 'view_pending' ? 'view' : ''} confirmModal`}
                centered
                open={isConfirmModal}
                closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button key="back" onClick={() => setIsConfirmModal(false)} block className="cancel">
                            Cancel
                        </Button>
                        <button
                            className="quit"
                            onClick={() => {
                                cancelRequest();
                                setOpenModal(false);
                                setIsConfirmModal(false);
                            }}
                        >
                            Quit
                        </button>
                    </div>
                }
            >
                <div className="modal-confirm">Your data won’t be saved! Are you sure you want to quit?</div>
            </Modal>
        </>
    );
};
