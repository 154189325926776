import React, { useState } from 'react';
import { Segmented } from 'antd';
import { SchoolUsers } from './SchoolUsers';
import { AdminsTenderRepresentatives } from './AdminsTenderRepresentatives';

export const ManageUsers = () => {
    const [role, setRole] = useState<string | number>('School Users');
    return (
        <div className="admin-panel__table-container">
            <div className="full-width">
                <Segmented
                    options={['School Users', 'Admins & Tender Representatives']}
                    value={role}
                    onChange={setRole}
                />
            </div>
            {role === 'School Users' && <SchoolUsers />}
            {role === 'Admins & Tender Representatives' && <AdminsTenderRepresentatives />}
        </div>
    );
};
